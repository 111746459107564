<template>
  <div class="tools-json">
    <div class="json-top">
      <div class="top-nav">
        <ul ref="topNav">
          <router-link :to="{ path: '/tools' }">
            <li class="default-home"><a>工 具 箱</a></li>
          </router-link>
          <li v-for="nav in navs" :key="nav.index" @click="clickTopNav($event, nav)">
            <a>{{ nav.title }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="json-center">
      <router-view @jsonDynamic="jsonDynamic"></router-view>
    </div>
    <div class="json-bottom">
      <em :class="jsonStatusClass">{{ jsonStatusMsg }}</em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolJson',
  components: {},
  data() {
    return {
      jsonStatusClass: '',
      jsonStatusMsg: '',
      topClass: '',
      navs: [
        {
          index: 1,
          title: 'JSON 在线解析',
          path: '/tools/json/analysis'
        },
        {
          index: 2,
          title: 'JSON 压缩/解压',
          path: '/tools/json/compression'
        },
        {
          index: 3,
          title: 'JSON 转义/取转义',
          path: ''
        },
        {
          index: 4,
          title: 'JSON 自动补齐',
          path: ''
        }
      ]
    }
  },
  methods: {
    jsonDynamic(json, errorMsg, isRight) {
      if (isRight) {
        this.jsonStatusClass = 'json-success'
        this.jsonStatusMsg = 'json 解析成功'
        this.$store.dispatch('tool/syncJsonContent', json)
      } else {
        this.jsonStatusClass = 'json-error'
        this.jsonStatusMsg = errorMsg
      }
    },
    getJsonData() {
      return this.$store.getters['tool/getJsonContent']
    },
    clickTopNav(e, nav) {
      const target = e.currentTarget
      const className = target.getAttribute('class')
      if (className !== 'top-nav-select') {
        const children = target.parentElement.children
        Array.from(children).forEach((element) => {
          element.removeAttribute('class')
        })
        target.setAttribute('class', 'top-nav-select')
        this.$store.dispatch('tool/syncToolJson', JSON.stringify(nav))
        this.$router.push({ path: nav.path })
      }
    }
  },
  mounted() {
    const nav = JSON.parse(this.$store.getters['tool/getToolJson'])
    if (nav != null) {
      Array.from(this.$refs.topNav.children).forEach((element, index) => {
        if (index === nav.index) {
          element.setAttribute('class', 'top-nav-select')
        } else {
          element.removeAttribute('class')
        }
      })
      this.$router.push({ path: nav.path })
    } else {
      this.$refs.topNav.children[1].setAttribute('class', 'top-nav-select')
    }
  }
}
</script>

<style scoped>
.tools-json {
  width: 100%;
  height: 100%;
  min-width: 1100px;
  overflow: hidden;
  background-color: #fff;
}

.tools-json ::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190);
}

.json-top {
  height: 9%;
  width: 100%;
  margin: 0 0 1% 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #eee;
}

.top-nav {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.top-nav ul {
  width: 80%;
  height: 100%;
  min-width: 800px;
  font-size: 15px;
  color: #0fd59d;
  font-style: oblique;
  font-family: monospace, Tahoma, 微软雅黑;
}

.top-nav ul li {
  float: left;
  width: 10%;
  height: 100%;
  min-width: 150px;
  display: flex;
  margin: 0 1%;
  border: 1px solid #fff;
  align-items: center;
  justify-content: space-around;
  transition: 0.8s all;
}

.top-nav ul li:hover {
  color: #fff;
  border: 1px solid #fff;
  transform: translateZ(0);
  background-color: #807dd4;
  box-shadow: 0 2px 20px -8px rgb(0 0 0 / 50%);
}

.default-home {
  background-color: #0fd59d;
  color: #fff;
}

.top-nav-select {
  color: #fff;
  border: 1px solid #fff;
  background-color: #807dd4;
  box-shadow: 0 2px 20px -8px rgb(0 0 0 / 50%);
}

.json-center {
  height: 80%;
  width: 98%;
  margin: 0 auto;
  border: 1.5px solid #eee;
}

.json-bottom {
  height: 10%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.json-bottom >>> * {
  -webkit-user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.json-bottom em {
  font-size: 16px;
  font-weight: 100;
  margin-left: 20px;
}

.json-success {
  color: #0fd59d;
}

.json-error {
  color: #ff0000;
}
</style>
